<template>
  <BaseDialog :model-value="modal.status" size-class="!max-w-xl" @update:model-value="close">
    <template #header v-if="!hasNoWatchlists">
      <template v-if="entity == 'coins'">
        <div class="flex items-center">
          <span class="font-medium">Select watchlist(s) to {{ modal.payload.mode }}</span>
          <div class="ml-2 flex items-center">
            <CoinIcon :coin="payload.uid" class="h-5 w-5" />
            <span class="font-medium">{{ payload.name }}</span>
          </div>
        </div>
      </template>
      <template v-if="entity == 'companies'">
        Select watchlist(s) to {{ modal.payload.mode }} {{ payload.name }}
      </template>
      <template v-if="entity == 'apefluencer_accounts'">
        Select watchlist(s) to {{ modal.payload.mode }} @{{ payload.screen_name }}
      </template>
    </template>
    <template #default>
      <template v-if="hasNoWatchlists && watchlistsReady">
        <div class="items-center text-center">
          <p>Looks like you don't have any watchlists yet.</p>
          <BaseButton @press="createWatchlist" class="mx-auto my-4">Create a Watchlist</BaseButton>
        </div>
      </template>
      <template v-else-if="watchlistOptions.length == 0 && watchlistsReady">
        <div class="my-4 flex items-center text-xs">
          <ExclamationCircleIcon class="mr-1 h-4 w-4" />
          {{ infoMessage }}
        </div>
      </template>
      <template v-else>
        <div class="py-4">
          <BaseSelect
            v-model="selectedWatchlists"
            multiple
            portal
            searchable
            button-width-class="w-full"
            dropdown-width-class="w-96"
            placeholder="Select Watchlists"
            :options="watchlistOptions"
          />
        </div>
      </template>
    </template>
    <template #footer v-if="!hasNoWatchlists">
      <div class="flex w-full justify-between">
        <BaseButton size="sm" type="secondary" @press="close">Cancel</BaseButton>
        <div class="flex space-x-2">
          <BaseButton size="sm" type="primary" @press="createWatchlist()" v-if="addMode"> Create Watchlist </BaseButton>
          <BaseButton
            size="sm"
            class="whitespace-nowrap"
            :busy="buttonBusy"
            :disabled="buttonDisabled"
            @press="addMode ? update() : remove()"
          >
            <template v-if="selectedWatchlists.length">
              {{ addMode ? 'Add to ' : 'Remove from ' }}
              {{ selectedWatchlists.length }} watchlists
            </template>
            <template v-else> No watchlist selected </template>
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useWatchlistStore } from '@/stores/watchlists';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';

const $store = useStore();
const $watchlistStore = useWatchlistStore();

//MODAL
const modal = computed(() => {
  return $store.getters.addToWatchlistModal ? $store.state.modal : { status: false };
});

watch(
  () => modal.value.status,
  () => {
    if (!modal.value.status) selectedWatchlists.value = [];
  }
);

const payload = computed(() => {
  return modal.value.payload?.payload || {};
});
const addMode = computed(() => {
  return modal.value.payload?.mode == 'add' || false;
});
const entity = computed(() => {
  return modal.value.payload ? modal.value.payload.entity : null;
});

const hasNoWatchlists = computed(() => {
  return allWatchlists.value.length == 0;
});
const infoMessage = computed(() => {
  let msg = 'Please create a watchlist first.';
  if (watchlistOptions.value.length == 0) {
    msg = addMode.value ? 'Already included in every watchlist.' : 'Not included in any watchlist yet.';
  }
  return msg;
});
function close() {
  allWatchlists.value.forEach(w => {
    w.enabled = false;
  });
  $store.commit('closeModal');
}

//BUTTON STATES
const buttonBusy = ref(false);
const buttonDisabled = computed(() => {
  return selectedWatchlists.value.length == 0;
});

//REQUEST PAYLOAD
function prepareRequestPayload() {
  return {
    watchlist_ids: selectedWatchlists.value,
    entity: modal.value.payload.entity,
    entity_id: modal.value.payload.entity == 'coins' ? payload.value.uid : payload.value.id,
    entity_info: modal.value.payload.payload
  };
}

//WATCHLISTS ACTIONS
const allWatchlists = ref([]);
const entityName = computed(() => $watchlistStore.getEntityName(entity.value));
const watchlists = computed(() => (entity.value ? $watchlistStore[entityName.value] : []));
const watchlistsReady = computed(() => (entity.value ? $watchlistStore.watchlistLoader[entityName.value] : false));

const selectedWatchlists = ref([]);
const watchlistOptions = computed(() => {
  if (addMode.value) {
    return allWatchlists.value
      .filter(x => !x.is_subscribed)
      .filter(x => !payload.value.watchlist_ids || !payload.value.watchlist_ids.includes(x.id));
  } else {
    return allWatchlists.value
      .filter(x => !x.is_subscribed)
      .filter(x => payload.value.watchlist_ids && payload.value.watchlist_ids.includes(x.id));
  }
});

watch(
  watchlists,
  () => {
    allWatchlists.value = watchlists.value.map(x => {
      return {
        ...x,
        label: x.name
      };
    });
  },
  { deep: true }
);

function createWatchlist() {
  $watchlistStore.visitCreatePage(modal.value.payload.entity, [modal.value.payload.payload.id]);
  close();
}

async function update() {
  const requestPayload = prepareRequestPayload();
  if (addMode.value) {
    buttonBusy.value = true;
    await $watchlistStore.updateWatchlistEntity(requestPayload);
    buttonBusy.value = false;
    close();
  }
}
async function remove() {
  const requestPayload = prepareRequestPayload();
  await $watchlistStore.deleteWatchlistEntity(requestPayload);
  close();
}
</script>
